/* ./components/Snaptube/Coronavirus/components/newIndex.js:18-33 (Box) */
._1pfpcy8 {
  align-items: center;
  background: rgba(239,251,255,1);
  border: 1px solid #76D3EE;
  border-radius: 20px;
  display: flex;
  flex: 1;
  justify-content: center;
  position: absolute;
  top: 10px;
  width: 20vw;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:18-33 (Box) */
._13ckx0u {
  right: 20px;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:18-33 (Box) */
._vbrrf9 {
  left: 20px;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:34-40 (Box) */
._1d7y1fq {
  color: #006280;
  font-family: Roboto-Black,Roboto;
  font-size: 10px;
  font-weight: 900;
  padding: 5px 0;
  text-align: center;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:463-470 (Box) */
._1upbz4a {
  background: #FFF;
  min-height: 100vh;
  min-width: 100vw;
  padding-bottom: 15vh;
  position: absolute;
  top: 0;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:473-479 (Box) */
._19fzw70 {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:480-494 (Box) */
._3hy3xh {
  background: rgba(0,0,0,0.5);
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 9;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:495-507 (Box) */
._96baxg {
  position: absolute;
  right: 5vw;
  top: 5vw;
  width: 6vw;
  z-index: 99;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:508-512 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:521-527 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:660-666 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:685-691 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:892-909 (Box) */
._17dlas6 {
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:514-520 (Box) */
._1ma8pi2 {
  margin: auto;
  position: relative;
  top: 10vh;
  width: 80%;
  z-index: 9;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:514-520 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:701-704 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:759-762 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:822-828 (Box) */
._18sk027 {
  text-align: left;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:514-520 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:701-704 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:759-762 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:822-828 (Box) */
._124375g {
  text-align: right;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:528-534 (Box) */
._pwhqd8 {
  background: #fff;
  border-radius: 0 0 20px 20px;
  min-height: 50vh;
  padding: 30px 20px 60px;
  position: relative;
  top: -5px;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:535-539 (Box) */
._1kdihk2 {
  color: #434343;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 18px;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:542-548 (Box) */
._1cxz0fh {
  color: #434343;
  font-size: 14px;
  max-height: 40vh;
  overflow: scroll;
  position: relative;
  white-space: pre-line;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:552-560 (Box) */
._1hbhtjt {
  bottom: 20px;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:562 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:585 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:845 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:867 (Box) */
._p0p5ez {
  color: #434343;
  display: inline-block;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:567-581 (Box) */
._191gor9 {
  color: #50C4FF;
  padding: 0 10px;
  text-decoration: none;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:599 (Box) */
._f39qpb {
  border-bottom: 1px solid #EFEFEF;
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:600 (Box) */
._aal8n7 {
  position: absolute;
  top: 0;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:615-626 (Box) */
._17zdh3t {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #434343;
  display: -webkit-box;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
  word-break: break-word;
  word-wrap: break-word;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:632 (Box) */
._16ham0h {
  padding-bottom: 35vw;
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:633-641 (Box) */
._rqpo2h {
  position: absolute;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:642-659 (Box) */
._1qr9o1r {
  position: absolute;
  right: 0;
  top: 1vh;
  width: 25vw;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:668-684 (Box) */
._ctq96c {
  bottom: 10%;
  left: 10%;
  position: absolute;
  width: 80%;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:701-704 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:759-762 (Box) */
._n6cfh6 {
  padding: 10px 20px;
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:729-734 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:787-792 (Box) */
._1fxl7ue {
  color: #333;
  font-family: Roboto-Bold,Roboto;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:740-744 (Box) */
/* ./components/Snaptube/Coronavirus/components/newIndex.js:806-810 (Box) */
._1f10nnv {
  border: 2px solid rgba(238,238,238,1);
  border-radius: 5px;
  margin: 0 20px;
  overflow: hidden;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:822-828 (Box) */
._od04lf {
  color: #333;
  font-family: Roboto-Bold,Roboto;
  font-size: 11px;
  font-weight: 400;
  padding: 10px 20px;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:836-843 (Box) */
._1oj05t7 {
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  left: 0;
  padding: 10px;
  text-align: center;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:849-863 (Box) */
._rgh6re {
  color: #50C4FF;
  margin: 0 10px;
  text-decoration: none;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:891 (Box) */
._1xvpa5k {
  margin: 20px auto 0;
  width: 50%;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:925-931 (Box) */
._u4kqs3 {
  background: rgba(255,255,255,0.7);
  bottom: 0;
  padding-top: 5px;
  position: fixed;
  width: 100%;
  z-index: 9;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:932-943 (Box) */
._1yr0lvt {
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:944 (Box) */
._ev6uxm {
  position: relative;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:945-952 (Box) */
._jhmb8p {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 70%;
}
/* ./components/Snaptube/Coronavirus/components/newIndex.js:954 (Box) */
._1pboekx {
  color: #8C8C8C;
  font-size: 12px;
  position: relative;
  text-align: center;
  top: -5px;
}
