/* ./components/Snaptube/Coronavirus/components/download.js:33-38 (Box) */
._1585uw6 {
  background-color: #264c4f;
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 0;
}
/* ./components/Snaptube/Coronavirus/components/download.js:39-43 (Box) */
._17dlas6 {
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/download.js:44 (Box) */
._pvmqs6 {
  bottom: 7%;
  position: absolute;
}
/* ./components/Snaptube/Coronavirus/components/download.js:57-61 (Box) */
._c5dx7k {
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/download.js:62-68 (Box) */
._mc1csv {
  width: 80%;
}
