/* ./components/Snaptube/Coronavirus/components/newDetail.js:19-28 (Box) */
._76bue6 {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  z-index: 9;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:30-34 (Box) */
._1viic3a {
  font-family: Roboto-Black,Roboto;
  font-size: 14px;
  font-weight: 900;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:30-34 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:57-63 (Box) */
._1q90hiu {
  color: #006280;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:30-34 (Box) */
._101srdk {
  color: #E8F9FF;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:37 (Box) */
._1lodczp {
  border-top: 2px solid #45BADD;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:45-56 (Box) */
._1pvc6rb {
  align-items: center;
  border: 1px solid #76D3EE;
  border-radius: 6px;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 5px;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:45-56 (Box) */
._pduuv0 {
  background: rgba(239,251,255,1);
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:45-56 (Box) */
._1gk4lwv {
  background: #fff;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:57-63 (Box) */
._1v8ypmd {
  font-family: Roboto-Black,Roboto;
  font-size: 10px;
  font-weight: 900;
  padding: 5px 0;
  text-align: center;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:57-63 (Box) */
._kx1fh6 {
  color: #333333;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:900 (Box) */
._xdkdbj {
  background-color: #fff;
  min-height: 200px;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:979 (Box) */
._a8b1av {
  display: flex;
  height: 10vw;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1039-1046 (Box) */
._107b70b {
  background: #FFF;
  min-height: 100vh;
  min-width: 100vw;
  padding-bottom: 5vh;
  position: absolute;
  top: 0;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1049-1055 (Box) */
._19fzw70 {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1056-1070 (Box) */
._3hy3xh {
  background: rgba(0,0,0,0.5);
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 9;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1071-1083 (Box) */
._96baxg {
  position: absolute;
  right: 5vw;
  top: 5vw;
  width: 6vw;
  z-index: 99;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1084-1088 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1097-1103 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1195-1201 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1220-1226 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1522-1537 (Box) */
._17dlas6 {
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1090-1096 (Box) */
._1ma8pi2 {
  margin: auto;
  position: relative;
  top: 10vh;
  width: 80%;
  z-index: 9;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1090-1096 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1248-1252 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1388-1391 (Box) */
._18sk027 {
  text-align: left;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1090-1096 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1248-1252 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1388-1391 (Box) */
._124375g {
  text-align: right;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1104-1110 (Box) */
._pwhqd8 {
  background: #fff;
  border-radius: 0 0 20px 20px;
  min-height: 50vh;
  padding: 30px 20px 60px;
  position: relative;
  top: -5px;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1111-1115 (Box) */
._1kdihk2 {
  color: #434343;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 18px;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1118-1124 (Box) */
._1cxz0fh {
  color: #434343;
  font-size: 14px;
  max-height: 40vh;
  overflow: scroll;
  position: relative;
  white-space: pre-line;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1128-1136 (Box) */
._1hbhtjt {
  bottom: 20px;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1140-1154 (Box) */
._191gor9 {
  color: #50C4FF;
  padding: 0 10px;
  text-decoration: none;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1168 (Box) */
._16ham0h {
  padding-bottom: 35vw;
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1169-1177 (Box) */
._rqpo2h {
  position: absolute;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1178-1194 (Box) */
._1qr9o1r {
  position: absolute;
  right: 0;
  top: 1vh;
  width: 25vw;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1203-1219 (Box) */
._ctq96c {
  bottom: 10%;
  left: 10%;
  position: absolute;
  width: 80%;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1231-1238 (Box) */
._hb79z1 {
  top: -1vw;
  width: 100%;
  z-index: 9;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1241 (Box) */
._1s3p33o {
  height: 1vh;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1248-1252 (Box) */
._1tbsiar {
  padding: 0 20px 20px 20px;
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1248-1252 (Box) */
._mi9ldd {
  margin-top: 10vw;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1253-1258 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1392-1397 (Box) */
._1al95ei {
  color: #333;
  font-family: Roboto-Bold,Roboto;
  font-size: 27px;
  font-weight: bold;
  line-height: 60px;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1261-1265 (Box) */
._1tvz7ww {
  color: #333;
  font-family: Roboto-Bold,Roboto;
  font-size: 11px;
  font-weight: 400;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1272 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1410 (Box) */
._1412pqv {
  margin: 0 20px;
  overflow: hidden;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1282 (Box) */
._19gn90 {
  padding-top: 20px;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1283 (Box) */
._1o4xrwi {
  padding: 0 0 5px 0;
  text-align: center;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1284-1287 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1423-1426 (Box) */
._14ph0ka {
  color: #434343;
  font-family: Roboto-Regular,Roboto;
  font-size: 16px;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1292 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1431 (Box) */
._vpi3w7 {
  color: #B9B499;
  padding: 0 10px 10px;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1295 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1434 (Box) */
._wpa5cv {
  margin: 10px;
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1296 (Box) */
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1435 (Box) */
._2po7sq {
  display: flex;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1388-1391 (Box) */
._dkuo37 {
  padding: 20px;
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1400-1404 (Box) */
._1g5nak3 {
  color: #333;
  font-family: Roboto-Bold,Roboto;
  font-size: 12px;
  font-weight: 400;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1418-1421 (Box) */
._123beob {
  background: #fff;
  border-radius: 0 0 40px 40px;
  padding-top: 20px;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1422 (Box) */
._ytcyeo {
  padding: 16px 0;
  text-align: center;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1521 (Box) */
._mipbma {
  margin: auto;
  width: 50%;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1541-1544 (Box) */
._110n5gw {
  height: 5vh;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1545-1548 (Box) */
._v21rju {
  margin-bottom: 70px;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1577-1583 (Box) */
._u4kqs3 {
  background: rgba(255,255,255,0.7);
  bottom: 0;
  padding-top: 5px;
  position: fixed;
  width: 100%;
  z-index: 9;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1584-1595 (Box) */
._1yr0lvt {
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1596 (Box) */
._ev6uxm {
  position: relative;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1597-1604 (Box) */
._jhmb8p {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 70%;
}
/* ./components/Snaptube/Coronavirus/components/newDetail.js:1606 (Box) */
._1pboekx {
  color: #8C8C8C;
  font-size: 12px;
  position: relative;
  text-align: center;
  top: -5px;
}
