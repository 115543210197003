/* ./components/Share/index.js:277 (Inline) */
._b60mfr {
  display: inline;
}
/* ./components/Share/index.js:281-286 (Row) */
._hao82f {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  display: flex;
  flex-direction: row;
  padding: 20px;
}
