/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:109-116 (Box) */
._bsevw6 {
  background-color: #fff;
  border-radius: 0 0 40px 40px;
  position: relative;
  top: -2px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:118-125 (Box) */
._4owjgr {
  color: #434343;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin: 0 20px;
  padding: 20px 0;
  text-align: center;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:128-130 (Box) */
._1391uh {
  margin: 0 0 40px;
  overflow-x: hidden;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:133 (Box) */
._15walw9 {
  z-index: 999;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:133 (Box) */
._1ythqaf {
  top: 9vw;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:137-147 (Box) */
._1hwmc9m {
  border-radius: 2px;
  color: #434343;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 0;
  text-align: center;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:137-147 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:165-174 (Box) */
._1u3k85j {
  width: 40vw;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:137-147 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:165-174 (Box) */
._14g1e0x {
  width: 20vw;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:155 (Box) */
._1nrqh3a {
  padding-top: 9vw;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:165-174 (Box) */
._15a0x6k {
  border-radius: 2px;
  color: #434343;
  font-size: 11px;
  font-weight: 500;
  padding: 15px 0;
  text-align: center;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:183 (Box) */
._173held {
  border-top: 1px solid #CFCFCF;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:193-203 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:215-225 (Box) */
._3pt7o9 {
  border-radius: 2px;
  color: #434343;
  font-size: 11px;
  font-weight: 500;
  padding: 10px 0;
  text-align: center;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:193-203 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:215-225 (Box) */
._1jhwo3n {
  width: 40%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:193-203 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:215-225 (Box) */
._w7eh1 {
  width: 20%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:193-203 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:215-225 (Box) */
._1lo9pts {
  background: #EBF6F8;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:193-203 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:215-225 (Box) */
._snd7er {
  background: #F4F9FA;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:241-258 (Box) */
._1veib03 {
  background: #EBF6F8;
  bottom: 0;
  font-weight: 600;
  margin-top: 1px;
  padding: 9px;
  position: relative;
  text-align: center;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CountryBlock.js:259-262 (Box) */
._10m9xwk {
  color: #008DFF;
  font-size: 14px;
  text-decoration: underline;
}
