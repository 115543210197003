/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:43 (Box) */
._1brcjor {
  background: #fff;
  display: flex;
  overflow-x: scroll;
}
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:44-50 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:67-73 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:90-96 (Box) */
._1gffvuk {
  border-right: 1px solid #fff;
  flex: 1;
  padding: 5px;
  text-align: center;
  width: 25%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:51 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:74 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:97 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:119 (Box) */
._m1mzc4 {
  font-size: 10px;
  line-height: 16px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:57-62 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:80-85 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:103-108 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:125-130 (Box) */
._19zwgdm {
  font-family: Roboto-Black,Roboto;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:65 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:88 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:111 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:133 (Box) */
._1l4bg63 {
  font-size: 10px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/WorldBlock.js:113-118 (Box) */
._wcsjow {
  flex: 1;
  padding: 5px;
  text-align: center;
  width: 25%;
}
