/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:41 (Box) */
._j0ljbf {
  background: #FFF;
  display: flex;
  overflow-x: scroll;
}
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:42-48 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:68-74 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:96-102 (Box) */
._1gffvuk {
  border-right: 1px solid #fff;
  flex: 1;
  padding: 5px;
  text-align: center;
  width: 25%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:49 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:75 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:103 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:127 (Box) */
._m1mzc4 {
  font-size: 10px;
  line-height: 16px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:55-60 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:81-86 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:109-114 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:133-138 (Box) */
._19zwgdm {
  font-family: Roboto-Black,Roboto;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:63 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:89 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:117 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:141 (Box) */
._1l4bg63 {
  font-size: 10px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/LocalBlock.js:121-126 (Box) */
._wcsjow {
  flex: 1;
  padding: 5px;
  text-align: center;
  width: 25%;
}
