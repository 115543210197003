/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:52 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:125 (Box) */
._1yr0lvt {
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:53-59 (Box) */
._ve2sty {
  color: #333;
  font-family: Roboto-Bold,Roboto;
  font-size: 18px;
  font-weight: bold;
  line-height: 60px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:53-59 (Box) */
._18sk027 {
  text-align: left;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:53-59 (Box) */
._124375g {
  text-align: right;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:64-88 (Box) */
._i8xeni {
  color: #008DFF;
  font-size: 14px;
  position: absolute;
  text-decoration-line: underline;
  top: 40%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:64-88 (Box) */
._1ng98tp {
  right: 10px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:64-88 (Box) */
._vxpbli {
  left: 10px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:93 (Box) */
._tvedi7 {
  display: flex;
  flex-wrap: wrap;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:100 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:179 (Box) */
._1rmse9u {
  width: 50%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:101-124 (Box) */
._16v7dtd {
  background: #fff;
  border-radius: 5px;
  margin: 5px;
  margin-bottom: 10px;
  padding: 5px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:128-139 (Box) */
._q8u5mh {
  border-radius: 10px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:143-155 (Box) */
._1601739 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #434343;
  display: -webkit-box;
  font-weight: 500;
  overflow: hidden;
  padding: 0 5px;
  text-overflow: ellipsis;
  word-break: break-word;
  word-wrap: break-word;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:143-155 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:191-203 (Box) */
._875u8n {
  direction: ltr;
  font-size: 12px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:143-155 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:191-203 (Box) */
._1fsv5xx {
  direction: rtl;
  font-size: 10px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:163-178 (Box) */
._9yv9mu {
  background: #fff;
  border-radius: 10px;
  display: flex;
  margin-bottom: 10px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:182-187 (Box) */
._1y3st9m {
  border-radius: 10px;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:190 (Box) */
._1l9dwi3 {
  padding: 0 10px;
  width: 50%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:191-203 (Box) */
._dnhl07 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  color: #434343;
  display: -webkit-box;
  font-weight: bold;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  word-wrap: break-word;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:206 (Box) */
._1tpjrhj {
  color: #434343;
  font-size: 14px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:231 (Box) */
._1esw3s5 {
  background: #FAFBFC;
  padding: 0 20px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:233-247 (Box) */
._1f2crw7 {
  background: rgba(0,0,0,0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:248-260 (Box) */
._93hg2o {
  position: absolute;
  right: 5vw;
  top: 10vw;
  width: 6vw;
  z-index: 99;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:261-265 (Box) */
._17dlas6 {
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:267 (Box) */
._4l1v4t {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:268 (Box) */
._1thjhto {
  padding-bottom: 20px;
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/Modal/VideoBlock.js:292 (Box) */
._zzi0wk {
  display: none;
}
