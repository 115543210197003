/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:107-114 (Box) */
._bsevw6 {
  background-color: #fff;
  border-radius: 0 0 40px 40px;
  position: relative;
  top: -2px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:117-124 (Box) */
._4owjgr {
  color: #434343;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin: 0 20px;
  padding: 20px 0;
  text-align: center;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:127-129 (Box) */
._1391uh {
  margin: 0 0 40px;
  overflow-x: hidden;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:132 (Box) */
._15walw9 {
  z-index: 999;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:132 (Box) */
._1ythqaf {
  top: 9vw;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:136-146 (Box) */
._mi2n4q {
  border-radius: 2px;
  color: #434343;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 0;
  text-align: center;
  width: 20%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:187-196 (Box) */
._wjqpsm {
  color: #434343;
  font-size: 11px;
  font-weight: 500;
  padding: 10px 0;
  text-align: center;
  width: 20%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:187-196 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:247-257 (Box) */
._tywg9x {
  border-bottom: 1px solid #cfcfcf;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:197-202 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:258-262 (Box) */
._y2j7l5 {
  align-items: center;
  justify-content: center;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:197-202 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:258-262 (Box) */
._10c54bd {
  display: flex;
  margin: auto;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:204-207 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:264-268 (Box) */
._1fsr5ca {
  border-bottom: 5px solid transparent;
  border-left: 5px solid #434343;
  border-top: 5px solid transparent;
  margin-left: 5px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:207-211 (Box) */
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:268-272 (Box) */
._12nab0h {
  border-bottom: 5px solid #434343;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-left: 5px;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:247-257 (Box) */
._17cdkih {
  border-radius: 2px;
  color: #434343;
  font-size: 11px;
  font-weight: 500;
  padding: 10px 0;
  text-align: center;
  width: 20%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:284 (Box) */
._1yr0lvt {
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:285-307 (Box) */
._14cnegc {
  align-items: center;
  background: linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
  bottom: 0;
  display: flex;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  padding: 9px;
  position: absolute;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:308-312 (Box) */
._1owv02b {
  color: #fff;
  font-size: 18px;
  padding-right: 5px;
  text-decoration: none;
}
/* ./components/Snaptube/Coronavirus/components/Modal/CityBlock.js:316-320 (Box) */
._1ynpvha {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #FFF;
}
