/* ./components/Snaptube/Coronavirus/components/video.js:116 (Box) */
._1yr0lvt {
  position: relative;
}
/* ./components/Snaptube/Coronavirus/components/video.js:117-121 (Box) */
._17dlas6 {
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/video.js:122-136 (Box) */
._1dkzhe1 {
  left: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 7%;
  z-index: 99;
}
/* ./components/Snaptube/Coronavirus/components/video.js:137-145 (Box) */
._fu5bqe {
  color: #003060;
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/video.js:150 (Box) */
._130g11v {
  position: relative;
  top: -5px;
}
/* ./components/Snaptube/Coronavirus/components/video.js:153-158 (Box) */
._u9cff1 {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
}
/* ./components/Snaptube/Coronavirus/components/video.js:169-180 (Box) */
._1snn69b {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #434343;
  display: -webkit-box;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  word-break: break-word;
  word-wrap: break-word;
}
